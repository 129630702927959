import { Component, Vue } from 'vue-property-decorator'
import DocumentPreview from '@/components/shared/DocumentPreview/MaterielReport/MaterielReport.vue'
import Commons from '@/components/shared/Helpers/commons'
import { Subscription } from 'rxjs'
import { PatientPrescriptionService } from '@/services/patient-prescription-service'
import { dossierPatientModel } from '@/models/dossier-response-model'
import { PrestationModel } from '@/models/prescriptions-model'
import { ValidationObserver } from 'vee-validate'
import ReportHelpers from '@/components/shared/Helpers/ReportHelpers'
import { ErrorService } from '@/services/error.service'
import Alert from '@/components/shared/Alert.vue'
import { PatientService } from '@/services/patient-service'
import { DossierAssurance } from '../../Static'
import { isRenewedFlagPending } from '@/views/Prescriptions/Constants'

@Component({
  components: {
    Alert,
    DocumentPreview,
    ValidationObserver
  }
})
export default class Report extends Vue {
  private subscriptions: Subscription[] = []
  private prescriptionService = PatientPrescriptionService.getInstance()
  private patientService = PatientService.getInstance()

  private selectedDossier: dossierPatientModel | null = null
  public prestation: PrestationModel|null = null
  public patientFullName = ''
  public loading = false
  public saving = false
  public errorMessages: string[] = []
  public dossierAssurance: DossierAssurance = {}
  public reseauPro: any[] = []
  public doctorName = ''

  public async mounted () {
    this.loading = true
    this.saving = false
    Commons.updateDossierName()
    this.subscriptions.push(this.prescriptionService.prestationSelected$.subscribe(selectedPrestation => {
      this.prestation = selectedPrestation
    }))
    if (this.prestation?.id) {
      await this.prescriptionService.GetDossierPatientByPrestationId(this.prestation.id)
        .then(data => {
          this.selectedDossier = data
        })
        .catch(async (errs) => {
          const res = await ErrorService.handleError(errs)
          this.errorMessages = res.errors
        })
    }
    if (!this.selectedDossier) {
      this.loading = false
      this.errorMessages = ["Impossible d'afficher le rapport, les données du patient n'ont pas pu être récupérées."]
      return
    }
    this.patientFullName = this.selectedDossier.fullName
    await Commons.getDossierReseau(this.selectedDossier.guid, this.errorMessages, this.reseauPro)
    await this.getDossierAssurance()
    this.loading = false
  }

  public async getDossierAssurance () {
    if (this.selectedDossier?.guid) {
      await this.patientService.getDossierAssuranceInfo(this.selectedDossier.guid).then(data => {
        this.dossierAssurance = data
      }).catch(async (errs) => {
        const res = await ErrorService.handleError(errs)
        this.errorMessages = res.errors
      })
    }
  }

  public async savePDF () {
    this.saving = true
    const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>
    const isValid = await observer.validate()
    if (isValid) {
      const documentPreview = this.$refs.documentpreview as any
      const model = ReportHelpers.GeneratePDFModel(5, `Rapport LIMA-${this.patientFullName}-${new Date().toLocaleDateString()}.pdf`, documentPreview,
        this.prestation?.prescriptionId!, this.doctorName, undefined)
      if (this.selectedDossier?.guid) {
        await ReportHelpers.UploadDocument(model, this.errorMessages, this.$router, this.selectedDossier.guid, this.isRenewal)
      }
    } else {
      const merged = Object.assign(this.$refs, (this.$refs.documentpreview as Vue).$refs)
      Commons.focusFirstComponentWithError(observer, merged, 'Ref')
    }
    this.saving = false
  }

  public get isRenewal () {
    return this.prestation?.isRenewed === isRenewedFlagPending
  }

  public goBack () {
    if (this.isRenewal) {
      sessionStorage.setItem('replayLastPendingSearch', JSON.stringify(true))
    } else {
      sessionStorage.removeItem('replayLastPendingSearch')
    }
    this.$router.go(-1)
  }

  public hideAlert () {
    this.errorMessages = []
  }

  public get datePrestation () {
    return this.prestation?.dateStart
  }

  public doctorChanged (doctorName) {
    this.doctorName = doctorName
  }
}
