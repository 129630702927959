import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { AppUser } from '@/models/app-user-dto'
import { AuthService } from '@/services/auth-service'
import { dossierPatientModel } from '@/models/dossier-response-model'
import { Subscription } from 'rxjs'
import { PatientPrescriptionService } from '@/services/patient-prescription-service'
import { PrestationModel, MaterielCategoryResponse } from '@/models/prescriptions-model'
import { langues } from '../../../../views/Patients/Dossier/Constants'
import { PatientService } from '@/services/patient-service'
import { DossierAssurance } from '../../../../views/Patients/Static'
import DocPreviewBase from '../DocPreviewBase.vue'
import AddressBloc from '../AddressBloc.vue'
import Commons from '@/components/shared/Helpers/commons'

@Component({
  components: {
    DocPreviewBase,
    AddressBloc
  }
})
export default class MaterielReport extends Vue {
  @Prop({ default: {} })
  public dossierAssurance!: DossierAssurance

  @Prop({ default: [] })
  public reseauPro!: any[]

  @Prop()
  public datePrestation!: string

  private subscriptions: Subscription[] = []
  private userService = AuthService.getInstance()
  private prescriptionService = PatientPrescriptionService.getInstance()
  private patientService = PatientService.getInstance()
  private selectedDossier: dossierPatientModel | any = null
  public patientFullName = ''
  public userFullName = ''
  public users: AppUser[] = []
  public medecinId = ''
  public medecin: any = null
  public prestation: PrestationModel | any = {}
  public langues = langues
  public insurance = ''
  public assurances: any[] = []
  public addressBloc: any[] = []
  public validity12Months = true
  public validityRenouvelable = false
  public materiels: string[] = []
  public materielCategories: MaterielCategoryResponse[] = []
  public displayAsteriskBlock = false
  public loaded = false

  public async mounted () {
    this.subscriptions.push(this.patientService.assuranceList$.subscribe((assurance: any[]) => {
      this.assurances = assurance
      if (!assurance.length) {
        this.patientService.getAllAssurance()
      }
      this.getDossierAssurance()
    }))
    this.subscriptions.push(this.prescriptionService.prestationSelected$.subscribe(selectedPrestation => {
      this.prestation = selectedPrestation
    }))
    if (this.prestation.id) {
      this.selectedDossier = await this.prescriptionService.GetDossierPatientByPrestationId(this.prestation.id)
    }
    if (!this.selectedDossier) {
      console.error('Unable to generate the report')
      return
    }
    this.patientFullName = this.selectedDossier.fullName

    this.userFullName = this.userService.fullName
    this.getDossierAssurance()
    this.materielCategories = this.prescriptionService.categories
    this.mapMaterielCategories()
    this.loaded = true
  }

  private mapMaterielCategories () {
    if (this.prestation?.materielCategoriesIds) {
      const prestationMateriels = this.materielCategories.filter(m => m.materielIds.some(x => this.prestation.materielCategoriesIds.includes(x)))
      let asterisk = ''
      prestationMateriels.forEach((materielCategorie: MaterielCategoryResponse) => {
        asterisk = ''
        if (materielCategorie.lima === '21.03.01.01.1') {
          asterisk = '*'
          this.displayAsteriskBlock = true
        }
        this.materiels.push(`<span class="doc-x-light">${materielCategorie.lima} - </span>${materielCategorie.description}${asterisk}`)
      })
    }
  }

  public getDossierAssurance () {
    if (this.selectedDossier?.guid) {
      this.mapInsuranceName()
    }
  }

  private mapInsuranceName () {
    this.insurance = this.assurances.find(x => x.id === this.dossierAssurance.assuranceId)?.assuranceName
  }

  public doctorChanged (doctorId: string) {
    this.medecin = this.reseauPro.find(x => x.reseauMedicalExtra?.reseauId === doctorId)
    this.$emit('doctorChanged', this.medecin?.fullname)
  }

  get languages () {
    if (this.selectedDossier?.languagesEnumIdList) {
      this.selectedDossier.languagesEnumIdList = Commons.transformLanguages(this.selectedDossier.languagesEnumIdList)
      return this.langues.filter(l => this.selectedDossier.languagesEnumIdList.some(x => Number(x) === Number(l.value))).map(a => a.text).join(", ")
    }
    return ''
  }

  get theDate () {
    return this.datePrestation ? new Date(this.datePrestation) : new Date()
  }
}
